.heading_heading__Q8rPg {
}

.heading_h1__1B0wf {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

@media (min-width: 640px) {

  .heading_h1__1B0wf {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 1024px) {

  .heading_h1__1B0wf {
    font-size: 3.75rem;
    line-height: 1;
  }
}

.heading_h2__Df51E {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (min-width: 640px) {

  .heading_h2__Df51E {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {

  .heading_h2__Df51E {
    font-size: 3rem;
    line-height: 1;
  }
}

.heading_h3__4ZRVq {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (min-width: 640px) {

  .heading_h3__4ZRVq {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 1024px) {

  .heading_h3__4ZRVq {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.heading_h4__vo4zM {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (min-width: 640px) {

  .heading_h4__vo4zM {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {

  .heading_h4__vo4zM {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.heading_h5__J_7Po {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (min-width: 640px) {

  .heading_h5__J_7Po {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {

  .heading_h5__J_7Po {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.heading_h6__MDiBz {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 640px) {

  .heading_h6__MDiBz {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {

  .heading_h6__MDiBz {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.heading_bold__LJkYd {
  font-weight: 700;
}

.heading_regular__37vIE {
  font-weight: 500;
}

.biggest-names-in-solar_logoItem__SpD5O {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}@media (min-width: 500px) {.biggest-names-in-solar_logoItem__SpD5O {
    width: auto;
  }
}@media (min-width: 640px) {.biggest-names-in-solar_logoItem__SpD5O {
    flex: 1 1 0%;
  }
}.biggest-names-in-solar_logoItem__SpD5O {
  min-width: 100px;
}

.biggest-names-in-solar_logoItem__SpD5O img {
  max-height: 60px;
}

.video-player_videoWrapper__UEP4d {

    aspect-ratio: 16 / 9;

    width: 100%
}

.video-player_videoWrapper__UEP4d video {

    max-width: 100%
}

.video-player_videoWrapper__UEP4d video & iframe {

    max-width: 100%
}

.footer_legal__PDuAd {

    border-top-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(229 231 235 / var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity));

    padding-top: 0.75rem;

    padding-bottom: 0.75rem;

    padding-left: 2rem;

    padding-right: 2rem
}

.footer_legalContent__b8nI_ {

    margin-left: auto;

    margin-right: auto;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(156 163 175 / var(--tw-text-opacity))
}

@media (min-width: 1024px) {

    .footer_legalContent__b8nI_ {

        width: 100%
    }

    @media (min-width: 425px) {

        .footer_legalContent__b8nI_ {

            max-width: 425px
        }
    }

    @media (min-width: 500px) {

        .footer_legalContent__b8nI_ {

            max-width: 500px
        }
    }

    @media (min-width: 640px) {

        .footer_legalContent__b8nI_ {

            max-width: 640px
        }
    }

    @media (min-width: 768px) {

        .footer_legalContent__b8nI_ {

            max-width: 768px
        }
    }

    @media (min-width: 1024px) {

        .footer_legalContent__b8nI_ {

            max-width: 1024px
        }
    }

    @media (min-width: 1280px) {

        .footer_legalContent__b8nI_ {

            max-width: 1280px
        }
    }

    @media (min-width: 1536px) {

        .footer_legalContent__b8nI_ {

            max-width: 1536px
        }
    }
}

.footer_legalSeparator__JnqBb {

    margin-left: 0.25rem;

    margin-right: 0.25rem
}

.footer_legalLink__ZW4hT {

    white-space: nowrap;

    --tw-text-opacity: 1;

    color: rgb(156 163 175 / var(--tw-text-opacity))
}

.footer_soc2Logo__XXqJV {

    margin-top: 1rem;

    display: flex;

    justify-content: flex-start;

    gap: 1rem
}

@media (min-width: 500px) {

    .footer_soc2Logo__XXqJV {

        margin-top: 2.5rem
    }
}

@media (min-width: 768px) {

    .footer_soc2Logo__XXqJV {

        margin-top: 1rem
    }

    .footer_soc2Logo__XXqJV {

        justify-content: flex-end
    }
}

.footer_soc2Logo__XXqJV img {

    max-height: 110px
}

@media (min-width: 768px) {

    .footer_soc2Logo__XXqJV img {

        max-height: 130px
    }
}

.footer_bestInShow2024__dEVch {

    display: inline-block
}

.footer_bestInShow2024__dEVch img {

    max-height: 10rem
}

@media (min-width: 768px) {

    .footer_bestInShow2024__dEVch img {

        max-height: 12rem
    }
}
.header_mobileMenuButton___tJW_ {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}.header_mobileMenuButton___tJW_:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity))
}

.header_mobileNav__NSJ5K > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.header_mobileNav__NSJ5K {
  overflow: auto;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  max-height: calc(100% - 4rem)
}

.header_mobileMenuButtonIcon__PA6e7 {
  display: block;
  height: 1.5rem;
  width: 1.5rem
}

.header_mobileNavLink__GT0Xo {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(30 59 85 / var(--tw-text-opacity))
}

.header_mobileNavLink__GT0Xo:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.header_desktopNav__QCu1A {
  display: flex
}

.header_desktopNav__QCu1A > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
}

@media (min-width: 1024px) {.header_desktopNav__QCu1A > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }
}

.header_desktopNavItem__Q2rEs {
  font-size: 0.875rem;
  line-height: 1.25rem
}

@media (min-width: 1024px) {.header_desktopNavItem__Q2rEs {
    font-size: 1rem;
    line-height: 1.5rem
  }
}

.header_desktopNavLink__gq_Fz {
  display: inline-flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(30 59 85 / var(--tw-text-opacity))
}

@media (min-width: 1024px) {.header_desktopNavLink__gq_Fz {
    padding-left: 1rem;
    padding-right: 1rem
  }
}

.header_navButton__0Kfqx {
  display: block;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  text-align: center
}

@media (min-width: 768px) {.header_navButton__0Kfqx {
    display: inline-flex
  }.header_navButton__0Kfqx {
    width: auto
  }.header_navButton__0Kfqx {
    align-items: center
  }.header_navButton__0Kfqx {
    justify-content: center
  }.header_navButton__0Kfqx {
    padding-left: 1rem;
    padding-right: 1rem
  }.header_navButton__0Kfqx {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }
}

.header_inactiveNavLink__IMnZV {
  font-weight: 300;
  --tw-text-opacity: 0.7
}

.header_inactiveNavLink__IMnZV:hover {
  --tw-text-opacity: 1
}

.header_inactiveNavLink__IMnZV:focus {
  --tw-text-opacity: 1
}

