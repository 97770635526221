.heading {
}

.h1 {
  composes: heading;
  @apply text-4xl sm:text-5xl lg:text-6xl;
}

.h2 {
  composes: heading;
  @apply text-3xl sm:text-4xl lg:text-5xl;
}

.h3 {
  composes: heading;
  @apply text-2xl sm:text-3xl lg:text-4xl;
}

.h4 {
  composes: heading;
  @apply text-xl sm:text-2xl lg:text-3xl;
}

.h5 {
  composes: heading;
  @apply text-lg sm:text-xl lg:text-2xl;
}

.h6 {
  composes: heading;
  @apply text-base sm:text-lg lg:text-xl;
}

.bold {
  @apply font-bold;
}

.regular {
  @apply font-medium;
}
