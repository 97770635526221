.mobileMenuButton {
  @apply inline-flex items-center justify-center p-2 rounded-md text-gray-400;
  @apply focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400;
}

.mobileNav {
  @apply px-2 pb-3 space-y-4 text-base shadow-lg bg-white overflow-auto;
  max-height: calc(100% - 4rem);
}

.mobileMenuButtonIcon {
  @apply block h-6 w-6;
}

.mobileNavLink {
  @apply text-navy flex items-center px-3 py-2 rounded-md font-medium focus:ring-0;
}

.desktopNav {
  @apply flex space-x-2.5 lg:space-x-4;
}

.desktopNavItem {
  @apply text-sm lg:text-base;
}

.desktopNavLink {
  @apply text-navy px-2 lg:px-4 py-2 font-medium inline-flex items-center;
}

.navButton {
  @apply w-full block text-center px-4 py-1.5;
  @apply md:inline-flex md:items-center md:justify-center md:w-auto md:px-4 md:py-1;
}

.inactiveNavLink {
  @apply text-opacity-70 hover:text-opacity-100 focus:text-opacity-100 font-light;
}
